import React from 'react';
import InnerHTML from 'dangerously-set-html-content'
export default function Download() {



const html ='<script> const urlParams = new URLSearchParams(window.location.search); document.getElementById("myText").innerHTML = "Thanks for downloading " + urlParams.get("d"); </script>'



  return (

    <section id="greeting" className="about-section-3 text-center">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 mx-auto ">
            <h2 className="text-white mb-4"><span id="myText"></span> </h2>
          </div>
        </div>
        <div className="row justify-content-center no-gutters mb-5 mb-lg-0">
          <div className="col-lg-5 justify-content-center ">
            <p className="text-white text-center">Please consider donating</p>
            <InnerHTML html={html} />
            <div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center mb-5 mb-lg-0">
          <div className="col-lg-2 justify-content-center">
            <a href="https://buy.stripe.com/eVaeVBgfvcH07tu28a" className="btn btn-down btn-primary w-100 p-3 mb-3 mr-4">$5</a>
          </div>
          <div className="col-lg-2 justify-content-center">
            <a href="https://buy.stripe.com/dR6dRx6EVfTc5lm001" className="btn btn-down btn-primary w-100 p-3 mb-3">$10</a>
          </div>
          <div className="col-lg-2 justify-content-center">
            <a href="https://buy.stripe.com/eVa3cT3sJ36q29acMM" className="btn btn-down btn-primary w-100 p-3 mb-3">$25</a>
          </div>
        </div>
      </div>
    </section>

  );
}
