import React from 'react';

export default function Goback() {
return (
<a href ="/">
<section  className="signup-section-2">
  <div className="container">
    <div className="row">
      <div className="col-md-8 col-lg-8 mx-auto text-center justify-content-center">
        <h2 className="text-white mb-5">back to mortified.space</h2>
      </div>
    </div>
  </div>
</section>
</a>

);
}
