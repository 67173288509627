import React from 'react';

import Layout from '../components/Layout';

import Thankyou from '../components/Thankyou';
import Goback from '../components/Goback';



const IndexPage = () => (
<Layout>
  <Thankyou />
  <Goback />
 </Layout>
);

export default IndexPage;
